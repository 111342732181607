<template>
    <div class="completebox">
        <div class="dp-f jc-c mb-24">
            <img src="@/assets/img/realname/success.png" class="w-52 h-52">
        </div>
        <div class="ta-c mb-100">实名认证已通过！</div>
        <oabutton class="m-0-a mb-30" width='462' height='48' title="返回首页" CSStype=2 style="border-radius: 4px;font-size: 18px;" @buttonclick="router_push_name('operation_worktable')"></oabutton>
    </div>
</template>
<script setup>
import { reactive, defineEmits } from "vue";
import {router_push_name} from "@/utils/server/router";
const state = reactive({
});
</script>
<style lang="scss" scoped >
.completebox{
    padding-top: 104px;
}
.mb-100{
    margin-bottom: 100px;
}
</style>